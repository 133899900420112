.countdown h2 {
  color: revert;
  top: 0.85em;
  position: relative;
  padding-bottom: 0.5em;
}

.countdown {
  margin: 0 auto;
  padding: 0rem;
  text-align: center;
  margin-bottom: 2em;
}
.countdown.primary {
  background-color: #b11116;
  color: #fff;
}
.countdown.secondary {
  background-color: #2e3233;
  color: #fff;
}
.countdown.tertiary {
  background-color: #ffb907;
  color: #333;
}

.countdown ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.countdown li {
  display: inline-block;
  font-size: 1em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

.countdown li span {
  display: block;
  font-size: 3.5em;
  margin-bottom: 20px;
}
.menu .countdown {
  margin-bottom: inherit;
  text-align: inherit;
}

@media (max-width: 375px) {
  .countdown li {
    font-size: 0.8em;
    padding: 0.8em;
  }
}

.video-hero {
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
  background: #000;
  z-index: 1;
}
.video-hero.full {
  height: 100vh;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  max-width: none !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
.video-hero .background-video video,
.video-hero .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}
.video-hero.full img.responsive {
  height: 100vh;
  width: 100vw;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0;
}
.video-hero .content {
  color: #fff;
  position: absolute;
  z-index: 1;
  padding: 12px;
  margin: 20px;
  text-align: left;
}
.video-hero.text-left .content {
  bottom: 0;
  left: 20px;
}
.video-hero.text-center .content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.video-hero.text-right .content {
  bottom: 0;
  right: 20px;
}
.video-hero .content.textbg-true {
  background: rgba(0, 0, 0, 0.2);
  /* Dark see-through background */
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  /* Blurry effect */
  border-radius: 1em;
  /* Optional: Add some rounded corners */
}
.video-hero .content div,
.video-hero .content p {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.videoHeading {
  font-weight: 600;
  margin-top: 0em;
  line-height: 1em;
  font-size: 4rem;
}
.preHeading {
  font-variant: all-small-caps;
  font-size: 1.2em;
}
.moreContent {
  color: #fff;
  position: absolute;
  z-index: 1;
  text-align: center;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  animation: fadeMoveDown 3s infinite;
  opacity: 0;
}
.video-hero a {
  text-decoration: none !important;
}
.video-hero.full.text-left .content,
.video-hero.full.text-right .content {
  margin-bottom: 15vh;
}
.video-hero.text-left .content {
  left: 10vw;
}
.video-hero.text-right .content {
  right: 10vw;
}
.background-video .playpause {
  position: absolute;
  bottom: 0;
  left: 2em;
  margin: 2em;
  background: transparent !important;
}
.primary .videoHeading {
  color: #007EB1;
}
.secondary .videoHeading {
  color: #826A6A;
}
.tertiary .videoHeading {
  color: #CACBCD;
}
@keyframes fadeMoveDown {
  100% {
    opacity: 0;
    transform: translate(-50%, -100px);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  0% {
    opacity: 0;
    transform: translate(-50%, 0);
  }
}
@media screen and (max-width: 425px) {
  .video-hero.text-left .content {
    left: 5px;
  }
  .video-hero.text-right .content {
    right: 5px;
  }
  .videoHeading {
    font-size: 1.5em;
  }
}

