.countdown h2 {
  color: revert;
  top: 0.85em;
  position: relative;
  padding-bottom: 0.5em;
}

.countdown {
  margin: 0 auto;
  padding: 0rem;
  text-align: center;
  margin-bottom: 2em;
}
.countdown.primary {
  background-color: #b11116;
  color: #fff;
}
.countdown.secondary {
  background-color: #2e3233;
  color: #fff;
}
.countdown.tertiary {
  background-color: #ffb907;
  color: #333;
}

.countdown ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.countdown li {
  display: inline-block;
  font-size: 1em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

.countdown li span {
  display: block;
  font-size: 3.5em;
  margin-bottom: 20px;
}
.menu .countdown {
  margin-bottom: inherit;
  text-align: inherit;
}

@media (max-width: 375px) {
  .countdown li {
    font-size: 0.8em;
    padding: 0.8em;
  }
}
